import { ComponentSingleStyleConfig } from "@chakra-ui/react";
import { SystemStyleFunction, mode } from "@chakra-ui/theme-tools";

const baseStyle: SystemStyleFunction = (props) => {
  return {
    rounded: "full",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    color: mode("inherit", "inherit")(props),
  };
};

const variantPrimary: SystemStyleFunction = (_props) => {
  return {
    color: "white",
    background: "linear-gradient(189deg, #53BFF6 0%, hsl(271, 74%, 28%) 100%)",

    _hover: {
      background:
        "linear-gradient(189deg, #8BD8FF 0%, hsl(271, 74%, 28%) 100%)",
    },
  };
};

const variantDominant: SystemStyleFunction = (_props) => {
  return {
    color: "white",
    background:
      "linear-gradient(189deg, rgb(255, 0, 128) 0%, hsl(271, 74%, 28%) 100%)",

    _hover: {
      background:
        "linear-gradient(192deg, rgb(255, 0, 128) 25%, hsl(271, 74%, 28%) 100%)",
    },
  };
};

export const Button: ComponentSingleStyleConfig = {
  baseStyle,

  variants: { primary: variantPrimary, dominant: variantDominant },
};
