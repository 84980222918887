import { API_WS_PATH } from "./environment";

export const ApiRoutes = {
  users: {
    create: "/v1/users/",
    createAssociation: "/v1/users/associations/",
  },

  events: {
    create: (topicId: string) => `/v1/events/${topicId}`,
  },

  gaze: {
    slots: "/v1/slots/",
    slot: (slotId: string) => `/v1/slots/${slotId}`,
    mySlots: "/v1/slots/mine/",
    acquireRoom: "/v1/slots/room/",

    roomSignal: (roomId: string) =>
      `${API_WS_PATH}/v1/video/session/?room=${roomId}`,
    adhocRoomSignal: `${API_WS_PATH}/v1/video/adhoc-session/`,
    adhocRoomCount: `${API_WS_PATH}/v1/video/adhoc-session/count`,
  },

  trial: {
    logSurvey: "/v1/trials/surveys/",
    uploadVideo: "/v1/trials/videos/",
  },
} as const;
