import { APP_NAME } from "@/api/environment";
import { Text, Heading, Spinner, Stack } from "@chakra-ui/react";

export function SplashScreen() {
  return (
    <Stack
      p="5%"
      height="100vh"
      width="100vw"
      textAlign="center"
      align="center"
      justify="center"
    >
      <Heading>{APP_NAME}</Heading>

      <Text>by The Institute for Love and Time</Text>
      <Spinner data-testid="splash-spinner" />
    </Stack>
  );
}
