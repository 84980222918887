export const isDevelopment = process.env.NODE_ENV === "development";
export const isProduction = process.env.NODE_ENV === "production";
export const isTest = process.env.NODE_ENV === "test";
export const isPreview = Boolean(process.env.NEXT_PUBLIC_GIT_COMMIT_SHA);
export const COMMIT_SHA =
  process.env.NEXT_PUBLIC_GIT_COMMIT_SHA || isProduction
    ? "production"
    : "development";
const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL;
export const API_PATH = `${API_BASE_URL}`;
export const API_WS_PATH = API_PATH.replace(/^http?/, "ws");

export const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME || "Vis-à-Vis";
